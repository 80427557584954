import React, { useEffect, useState } from "react";
import "./WallColor.scss";

interface Props {
  formData: any;
  setFormData: any;
  navigation: any;
  model: any;
  totalSum: string;
}

function WallColor({
  formData,
  setFormData,
  navigation,
  model,
  totalSum,
}: Props) {
  // should fetch wallColor option files based on wallColor.texture
  const [images, setImages] = useState<any>({
    greyWalls: "",
    lightWalls: "",
  });

  useEffect(() => {
    const importImages = async () => {
      try {
        const greyWalls = await import(
          `../../../gfx/${model}/wallColor/${formData.floorType.color}FloorGreyWalls.jpg`
        );
        const lightWalls = await import(
          `../../../gfx/${model}/wallColor/${formData.floorType.color}FloorLightWalls.jpg`
        );

        setImages({ greyWalls, lightWalls });
      } catch (e) {
        console.log("error ----------", e);
      }
    };

    importImages();
  }, [formData.floorType.color, model]);

  return (
    <section className="wallColor">
      <div className="wallColor__image_wrapper">
        <img
          src={images.greyWalls.default}
          alt="grey walls"
          className={`wallColor__image ${
            formData.wallColor.color === "Light" && "wallColor__image__inactive"
          }`}
        ></img>
        <img
          src={images.lightWalls.default}
          alt="light walls"
          className={`wallColor__image wallColor__image__secondary ${
            formData.wallColor.color === "Light" &&
            "wallColor__image__secondary_active"
          }`}
        ></img>
      </div>
      <div className="wallColor__content">
        <h2 className="wallColor__title">Your Wall Color:</h2>
        <button
          className={`wallColor__button ${
            formData.wallColor.color === "Light" && "wallColor__button_active"
          }`}
          onClick={() =>
            setFormData({
              target: {
                name: "wallColor",
                value: { color: "Light", price: 0 },
              },
            })
          }
        >
          <p className="wallColor__button__paragraph">Light</p>
          <p className="wallColor__button__paragraph">0 &#36;</p>
        </button>
        <button
          className={`wallColor__button ${
            formData.wallColor.color === "Dark" && "wallColor__button_active"
          }`}
          onClick={() => {
            setFormData({
              target: {
                name: "wallColor",
                value: { color: "Dark", price: 1540 },
              },
            });
          }}
        >
          <p className="wallColor__button__paragraph">Dark</p>
          <p className="wallColor__button__paragraph">1,540 &#36;</p>
        </button>
        <div className="wallColor__price">
          <p>Total Price</p>
          <p>
            {totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#36;
          </p>
        </div>
        <button
          className="wallColor__price__button"
          onClick={() => navigation.next()}
        >
          NEXT
        </button>
      </div>
    </section>
  );
}

export default WallColor;

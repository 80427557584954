import React from "react";
import "./FloorType.scss";

import lightFloor from "../../../gfx/KLX/floorType/light.jpg";
import darkFloor from "../../../gfx/KLX/floorType/dark.jpg";

interface Props {
  formData: any;
  setFormData: any;
  navigation: any;
  model: any;
  totalSum: string;
}

function FloorType({
  formData,
  setFormData,
  navigation,
  totalSum,
  model,
}: Props) {
  // based on model images and prices

  return (
    <section className="floorType">
      <div className="floorType__image_wrapper">
        <img
          src={darkFloor}
          alt="light floor"
          className={`floorType__image ${
            formData.floorType.color === "Engineered" &&
            "floorType__image__inactive"
          }`}
        ></img>
        <img
          src={lightFloor}
          alt="dark floor"
          className={`floorType__image floorType__image__secondary ${
            formData.floorType.color === "Engineered" &&
            "floorType__image__secondary_active"
          }`}
        ></img>
      </div>
      <div className="floorType__content">
        <h2 className="floorType__title">Your Flooring:</h2>
        <button
          className={`floorType__button ${
            formData.floorType.color === "Vinyl" && "floorType__button_active"
          }`}
          onClick={() =>
            setFormData({
              target: {
                name: "floorType",
                value: { color: "Vinyl", price: 0 },
              },
            })
          }
        >
          <p className="floorType__button__paragraph">Vinyl</p>
          <p className="floorType__button__paragraph">0 &#36;</p>
        </button>
        <button
          className={`floorType__button ${
            formData.floorType.color === "Engineered" &&
            "floorType__button_active"
          }`}
          onClick={() =>
            setFormData({
              target: {
                name: "floorType",
                value: { color: "Engineered", price: 2164.5 },
              },
            })
          }
        >
          <p className="floorType__button__paragraph">Engineered</p>
          <p className="floorType__button__paragraph">2,164.50 &#36;</p>
        </button>
        <div className="floorType__price">
          <p>Total Price</p>
          <p>
            {totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#36;
          </p>
        </div>
        <button
          className="floorType__price__button"
          onClick={() => navigation.next()}
        >
          NEXT
        </button>
      </div>
    </section>
  );
}

export default FloorType;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import WallTexture from "./WallTexture/WallTexture";
import Floorplan from "./Floorplan/Floorplan";
import FloorType from "./FloorType/FloorType";
import WallColor from "./WallColor/WallColor";
import Appliance from "./Appliance/Appliance";
import Summary from "./Summary/Summary";
import "./Constructor.scss";

type Texture = "Cementitious" | "Timber";
type floorWidth = 39 | 42;
type colorType = "Light" | "Dark";
type price = number;

interface WallT {
  texture: Texture;
  price: price;
}

interface Floor {
  color: "Vinyl" | "Engineered";
  price: price;
}

interface Walls {
  color: colorType;
  price: price;
}

interface LivingRoom {
  oven: {
    withOven: boolean;
    price: price;
  };
  dishwasher: {
    withDishwasher: boolean;
    price: price;
  };
  washer: {
    withWasher: boolean;
    price: price;
  };
  dryer: {
    withDryer: boolean;
    price: price;
  };
}

interface Appl {
  livingRoom: LivingRoom;
}

interface FloorP {
  width: floorWidth;
  price: number;
}

interface DefaultData {
  modelPrice: number;
  wallTexture: WallT;
  floorPlan: FloorP;
  floorType: Floor;
  wallColor: Walls;
  appliance: Appl;
}

interface Steps {
  id: string;
  name: string;
}

// interface InternalProps {
//   formData: FormData;
//   setFormData: SetForm;
//   navigation: NavigationProps;
// }

type LocationState = { model: string };

function Constructor() {
  let model;
  const location = useLocation().state;

  if (location) {
    model = (location as LocationState).model;
  } else {
    model = "modelB";
  }

  const steps: Steps[] = [
    { id: "1", name: "Floorplan" },
    { id: "2", name: "Façade" },
    { id: "3", name: "Flooring" },
    { id: "4", name: "Wall Color" },
    { id: "5", name: "Appliances" },
    { id: "6", name: "Summary" },
  ];

  // should be switch function based on modal it should make default prices

  const defaultData: DefaultData = {
    modelPrice: 210000,
    wallTexture: { texture: "Cementitious", price: 0 },
    floorPlan: { width: 39, price: 0 },
    floorType: { color: "Vinyl", price: 0 },
    wallColor: { color: "Light", price: 0 },
    appliance: {
      livingRoom: {
        oven: { withOven: false, price: 0 },
        dishwasher: { withDishwasher: false, price: 0 },
        washer: { withWasher: false, price: 0 },
        dryer: { withDryer: false, price: 0 },
      },
    },
  };

  const [formData, setFormData] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });
  const [totalSum, setTotalSum] = useState(
    formData.modelPrice +
      formData.floorPlan.price +
      formData.wallTexture.price +
      formData.floorType.price +
      formData.wallColor.price +
      formData.appliance.livingRoom.oven.price +
      formData.appliance.livingRoom.dishwasher.price +
      formData.appliance.livingRoom.washer.price +
      formData.appliance.livingRoom.dryer.price
  );

  useEffect(() => {
    setTotalSum(
      formData.modelPrice +
        formData.floorPlan.price +
        formData.wallTexture.price +
        formData.floorType.price +
        formData.wallColor.price +
        formData.appliance.livingRoom.oven.price +
        formData.appliance.livingRoom.dishwasher.price +
        formData.appliance.livingRoom.washer.price +
        formData.appliance.livingRoom.dryer.price
    );
  }, [
    formData.modelPrice,
    formData.floorPlan.price,
    formData.wallTexture.price,
    formData.floorType.price,
    formData.wallColor.price,
    formData.appliance.livingRoom.oven.price,
    formData.appliance.livingRoom.dishwasher.price,
    formData.appliance.livingRoom.washer.price,
    formData.appliance.livingRoom.dryer.price,
  ]);

  const props = { formData, setFormData, navigation, model, totalSum };

  const handleNavigation = (id: string) => {
    (navigation as any).go(id);
  };

  const renderSwitch = (step: any, props: any) => {
    switch (step.name) {
      case "Floorplan":
        return <Floorplan {...props} />;
      case "Façade":
        return <WallTexture {...props} />;
      case "Flooring":
        return <FloorType {...props} />;
      case "Wall Color":
        return <WallColor {...props} />;
      case "Appliances":
        return <Appliance {...props} />;
      case "Summary":
        return <Summary {...props} />;
      default:
        return <WallTexture {...props} />;
    }
  };

  return (
    <section className="constructor">
      <div className="constructor__menu">
        {steps.map((item) => (
          <p
            onClick={() => handleNavigation(item.id)}
            key={item.name}
            className={`constructor__menu__link ${
              item.name === step["name"] && "constructor__menu__link_active"
            }`}
          >
            {item.name}
          </p>
        ))}
      </div>
      {renderSwitch(step, props)}
    </section>
  );
}

export default Constructor;

import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import floorPlanWithTerrace from "../../../gfx/KLX/floorPlan/floorPlanWithTerrace.jpeg";
import secondFloorWithTerrace from "../../../gfx/KLX/floorPlan/2floorPlanWithTerrace.jpeg";
import floorPlanWithoutTerrace from "../../../gfx/KLX/floorPlan/floorPlanWithoutTerrace.jpeg";
import secondFloorWithoutTerrace from "../../../gfx/KLX/floorPlan/2floorPlanWithoutTerrace.jpeg";
import timeline from "../../../gfx/timeline.png";

import "./Summary.scss";
import "swiper/css";
import "swiper/css/navigation";

interface Props {
  formData: any;
  setFormData: any;
  navigation: any;
  model: any;
  totalSum: string;
}

function Summary({
  formData,
  setFormData,
  navigation,
  totalSum,
  model,
}: Props) {
  const [isOfferProposed, setOfferProposed] = useState<boolean>(false);

  const [withAppliance] = useState<boolean>(
    formData.appliance.livingRoom.oven.withOven ||
      formData.appliance.livingRoom.dishwasher.withDishwasher ||
      formData.appliance.livingRoom.washer.withWasher ||
      formData.appliance.livingRoom.dryer.withDryer
  );

  const styleAppliance = () =>
    withAppliance
      ? {
          display: "block",
        }
      : { display: "none" };

  const styleExterior = () =>
    withAppliance
      ? { borderBottom: "1px solid black", paddingBottom: "10px" }
      : { borderBottom: "none" };

  const imagesSection = () => (
    <div className="summary__image_wrapper">
      <Swiper loop navigation modules={[Navigation]} className="summary__image">
        <SwiperSlide>
          <div className="summary__image_wrapper">
            <img
              src={
                formData.floorPlan.width === 42
                  ? floorPlanWithTerrace
                  : floorPlanWithoutTerrace
              }
              alt="Floorplan with terrace"
              className="summary__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="summary__image_wrapper">
            <img
              src={
                formData.floorPlan.width === 42
                  ? secondFloorWithTerrace
                  : secondFloorWithoutTerrace
              }
              alt="Floorplan without terrace"
              className="summary__image"
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <img src={timeline} alt="Timeline" className="summary__image" />
    </div>
  );

  return (
    <section className="summary">
      <div>
        {isOfferProposed && (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
            }}
            onSubmit={(values) => {
              alert(JSON.stringify(values, null, 2));
            }}
          >
            <Form className="summary__form">
              <label className="summary__form_label" htmlFor="firstName">
                First Name&#42;
              </label>
              <Field
                className="summary__form_field"
                id="firstName"
                name="firstName"
                placeholder="Your First Name"
              />

              <label className="summary__form_label" htmlFor="lastName">
                Last Name&#42;
              </label>
              <Field
                className="summary__form_field"
                id="lastName"
                name="lastName"
                placeholder="Your Last Name"
              />

              <label className="summary__form_label" htmlFor="email">
                Email
              </label>
              <Field
                className="summary__form_field"
                id="email"
                name="email"
                placeholder="Your Email"
                type="email"
              />
              <button
                className="summary__price__button summary__form__button"
                type="submit"
              >
                Get Offer
              </button>
            </Form>
          </Formik>
        )}
        {imagesSection()}
      </div>

      <div className="summary__content">
        <h2 className="summary__title">Summary:</h2>
        <div className="summary__div_model">
          <p className="summary__paragraph_with_padding">{model}</p>
          <p className="summary__paragraph_with_padding">
            {formData.modelPrice
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            &#36;
          </p>
        </div>

        <div className="summary__div_with_border">
          <p className="summary__paragraph_with_padding">INTERIOR</p>
          <div className="summary__div_def">
            <p className="summary__paragraph">
              Floor: {formData.floorType.color}
            </p>
            <p className="summary__paragraph">
              {formData.floorType.price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              &#36;
            </p>
          </div>
          <div className="summary__div_def">
            <p className="summary__paragraph">
              Wall Color: {formData.wallColor.color}
            </p>
            <p className="summary__paragraph">
              {formData.wallColor.price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              &#36;
            </p>
          </div>
        </div>

        <div style={styleExterior()}>
          <p className="summary__paragraph_with_padding">EXTERIOR</p>
          <div className="summary__div_def">
            <p className="summary__paragraph">
              Façade: {formData.wallTexture.texture}
            </p>
            <p className="summary__paragraph">
              {formData.wallTexture.price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              &#36;
            </p>
          </div>
          {formData.floorPlan.width === 42 && (
            <div className="summary__div_def">
              <p className="summary__paragraph">Terrace</p>
              <p className="summary__paragraph">
                {formData.floorPlan.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                &#36;
              </p>
            </div>
          )}
        </div>

        <div style={styleAppliance()}>
          <p className="summary__paragraph_with_padding">APPLIANCES</p>

          {formData.appliance.livingRoom.oven.withOven && (
            <div className="summary__div_def">
              <p className="summary__paragraph">Miele Oven</p>
              <p className="summary__paragraph">
                {formData.appliance.livingRoom.oven.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                &#36;
              </p>
            </div>
          )}
          {formData.appliance.livingRoom.dishwasher.withDishwasher && (
            <div className="summary__div_def">
              <p className="summary__paragraph">Miele Dishwasher</p>
              <p className="summary__paragraph">
                {formData.appliance.livingRoom.dishwasher.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                &#36;
              </p>
            </div>
          )}
          {formData.appliance.livingRoom.washer.withWasher && (
            <div className="summary__div_def">
              <p className="summary__paragraph">Miele Washer</p>
              <p className="summary__paragraph">
                {formData.appliance.livingRoom.washer.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                &#36;
              </p>
            </div>
          )}
          {formData.appliance.livingRoom.dryer.withDryer && (
            <div className="summary__div_def">
              <p className="summary__paragraph">Miele Dryer</p>
              <p className="summary__paragraph">
                {formData.appliance.livingRoom.dryer.price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                &#36;
              </p>
            </div>
          )}
        </div>

        <div className="summary__price">
          <p>Total Price</p>
          <p>
            {totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#36;
          </p>
        </div>
        {!isOfferProposed && (
          <button
            className="summary__price__button"
            onClick={() => setOfferProposed(true)}
          >
            GET OFFER
          </button>
        )}
      </div>
    </section>
  );
}

export default Summary;

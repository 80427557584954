import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./Appliance.scss";

interface Props {
  formData: any;
  setFormData: any;
  navigation: any;
  model: any;
  totalSum: string;
}

function Appliance({
  formData,
  setFormData,
  navigation,
  model,
  totalSum,
}: Props) {
  const appliances = [
    { shortName: "oven", name: "Miele Oven", price: "2139" },
    { shortName: "dishwasher", name: "Miele Dishwasher", price: "1799" },
    { shortName: "washer", name: "Miele Washer", price: "1259" },
    { shortName: "dryer", name: "Miele Dryer", price: "1259" },
  ];

  // should fetch appliance option files based on appliance.texture
  const [images, setImages] = useState<any>({
    withOven: "",
    withoutOven: "",
    withWasher: "",
    withoutWasher: "",
  });

  const [appliance, setAppliance] = useState({
    oven: formData.appliance.livingRoom.oven.withOven,
    dishwasher: formData.appliance.livingRoom.dishwasher.withDishwasher,
    washer: formData.appliance.livingRoom.washer.withWasher,
    dryer: formData.appliance.livingRoom.dryer.withDryer,
  });

  useEffect(() => {
    const importImages = async () => {
      try {
        const withOven = await import(
          `../../../gfx/${model}/appliance/${formData.floorType.color}FloorWithOven.jpg`
        );
        const withoutOven = await import(
          `../../../gfx/${model}/appliance/${formData.floorType.color}FloorWithoutOven.jpg`
        );

        const withWasher = await import(
          `../../../gfx/${model}/appliance/${formData.wallColor.color}Walls${formData.floorType.color}FloorWithWasher.jpeg`
        );
        const withoutWasher = await import(
          `../../../gfx/${model}/appliance/${formData.wallColor.color}Walls${formData.floorType.color}FloorWithoutWasher.jpeg`
        );

        setImages({ withOven, withoutOven, withWasher, withoutWasher });
      } catch (e) {
        console.log("error ----------", e);
      }
    };

    importImages();
  }, [formData.floorType.color, formData.wallColor.color, model]);

  const handleInputChange = (e: any) => {
    switch (e.target.id) {
      case "oven":
        setAppliance({ ...appliance, oven: !appliance.oven });
        setFormData({
          target: {
            name: "appliance",
            value: {
              livingRoom: {
                ...formData.appliance.livingRoom,
                oven: {
                  withOven: !appliance.oven,
                  price: !appliance.oven ? 1839 : 0,
                },
              },
            },
          },
        });
        break;
      case "dishwasher":
        setAppliance({ ...appliance, dishwasher: !appliance.dishwasher });
        setFormData({
          target: {
            name: "appliance",
            value: {
              livingRoom: {
                ...formData.appliance.livingRoom,
                dishwasher: {
                  withDishwasher: !appliance.dishwasher,
                  price: !appliance.dishwasher ? 1499 : 0,
                },
              },
            },
          },
        });
        break;
      case "washer":
        setAppliance({ ...appliance, washer: !appliance.washer });
        setFormData({
          target: {
            name: "appliance",
            value: {
              livingRoom: {
                ...formData.appliance.livingRoom,
                washer: {
                  withWasher: !appliance.washer,
                  price: !appliance.washer ? 959 : 0,
                },
              },
            },
          },
        });
        break;
      case "dryer":
        setAppliance({ ...appliance, dryer: !appliance.dryer });
        setFormData({
          target: {
            name: "appliance",
            value: {
              livingRoom: {
                ...formData.appliance.livingRoom,
                dryer: {
                  withDryer: !appliance.dryer,
                  price: !appliance.dryer ? 959 : 0,
                },
              },
            },
          },
        });
    }
  };

  return (
    <section className="appliance">
      <div className="appliance__image_wrapper">
        <Swiper
          loop
          navigation
          modules={[Navigation]}
          className="appliance__image"
        >
          <SwiperSlide>
            <div className="wallTexture__image_wrapper">
              <img
                src={
                  formData.appliance.livingRoom.oven.withOven
                    ? images.withOven.default
                    : images.withoutOven.default
                }
                alt="withOven"
                className="appliance__image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="appliance__image_wrapper">
              <img
                src={
                  formData.appliance.livingRoom.dryer.withDryer ||
                  formData.appliance.livingRoom.washer.withWasher
                    ? images.withWasher.default
                    : images.withoutWasher.default
                }
                alt="with/without washer"
                className="appliance__image"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="appliance__content">
        <h2 className="appliance__title">Appliance Options:</h2>
        {appliances.map((item) => (
          <label key={item.shortName} className="appliance__label">
            <div className="appliance__div_wrapper">
              <input
                className="appliance__input"
                // defaultChecked={appliance[item.shortName]}
                checked={appliance[item.shortName]}
                onClick={(e) => {
                  handleInputChange(e);
                }}
                type="radio"
                id={item.shortName}
              />
              <div className="appliance__div_button" />
              <p>{item.name}</p>
            </div>
            <p>
              {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              &#36;
            </p>
          </label>
        ))}

        <div className="appliance__price">
          <p>Total Price</p>
          <p>
            {totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#36;
          </p>
        </div>
        <button
          className="appliance__price__button"
          onClick={() => navigation.next()}
        >
          NEXT
        </button>
      </div>
    </section>
  );
}

export default Appliance;

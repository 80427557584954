import React, { useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import "./WallTexture.scss";

import FrontViewCementitious from "../../../gfx/KLX/wallTexture/frontViewCementitious.png";
import FrontViewTimber from "../../../gfx/KLX/wallTexture/frontViewTimber.png";

interface Props {
  formData: any;
  setFormData: any;
  navigation: any;
  model: any;
  totalSum: string;
}

function WallTexture({
  formData,
  setFormData,
  navigation,
  totalSum,
  model,
}: Props) {
  // based on model images and prices
  const [images, setImages] = useState<any>({
    backViewCementitious: "",
    backViewTimber: "",
  });

  useLayoutEffect(() => {
    const importImages = async () => {
      try {
        const backViewCementitious = await import(
          `../../../gfx/${model}/wallTexture/${String(
            formData.floorPlan.width
          )}Cementitious.png`
        );
        const backViewTimber = await import(
          `../../../gfx/${model}/wallTexture/${formData.floorPlan.width}Timber.png`
        );

        setImages({ backViewCementitious, backViewTimber });
      } catch (e) {
        console.log("error ----------", e);
      }
    };

    importImages();
  }, [formData.floorPlan.width, model]);

  return (
    <section className="wallTexture">
      <div className="wallTexture__swiper_wrapper">
        <Swiper
          loop
          navigation
          modules={[Navigation]}
          className={`wallTexture__image wallTexture__image__first ${
            formData.wallTexture.texture === "Timber" &&
            "wallTexture__image__inactive"
          }`}
        >
          <SwiperSlide>
            <div className="wallTexture__image_wrapper">
              <img
                src={FrontViewCementitious}
                alt="standard texture"
                className="wallTexture__image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="wallTexture__image_wrapper">
              <img
                src={images.backViewCementitious.default}
                alt="standard texture"
                className="wallTexture__image"
              />
            </div>
          </SwiperSlide>
        </Swiper>

        <Swiper
          loop
          navigation
          modules={[Navigation]}
          className={`wallTexture__image wallTexture__image__secondary ${
            formData.wallTexture.texture === "Timber" &&
            "wallTexture__image__secondary_active"
          }`}
        >
          <SwiperSlide>
            <div className="wallTexture__image_wrapper">
              <img
                src={FrontViewTimber}
                alt="standard texture"
                className="wallTexture__image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="wallTexture__image_wrapper">
              <img
                src={images.backViewTimber.default}
                alt="standard texture"
                className="wallTexture__image"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="wallTexture__content">
        <h2 className="wallTexture__title">Your Façade:</h2>
        <button
          className={`wallTexture__button ${
            formData.wallTexture.texture === "Cementitious" &&
            "wallTexture__button_active"
          }`}
          onClick={() =>
            setFormData({
              target: {
                name: "wallTexture",
                value: { texture: "Cementitious", price: 0 },
              },
            })
          }
        >
          <p className="wallTexture__button__paragraph">Cementitious</p>
          <p className="wallTexture__button__paragraph">0 &#36;</p>
        </button>
        <button
          className={`wallTexture__button ${
            formData.wallTexture.texture === "Timber" &&
            "wallTexture__button_active"
          }`}
          onClick={() =>
            setFormData({
              target: {
                name: "wallTexture",
                value: { texture: "Timber", price: 10000 },
              },
            })
          }
        >
          <p className="wallTexture__button__paragraph">Timber</p>
          <p className="wallTexture__button__paragraph">10,000 &#36;</p>
        </button>
        <div className="wallTexture__price">
          <p>Total Price</p>
          <p>
            {totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#36;
          </p>
        </div>
        <button
          className="wallTexture__price__button"
          onClick={() => navigation.next()}
        >
          NEXT
        </button>
      </div>
    </section>
  );
}

export default WallTexture;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import floorPlanWithTerrace from "../../../gfx/KLX/floorPlan/floorPlanWithTerrace.jpeg";
import secondFloorWithTerrace from "../../../gfx/KLX/floorPlan/2floorPlanWithTerrace.jpeg";
import floorPlanWithoutTerrace from "../../../gfx/KLX/floorPlan/floorPlanWithoutTerrace.jpeg";
import secondFloorWithoutTerrace from "../../../gfx/KLX/floorPlan/2floorPlanWithoutTerrace.jpeg";

import "swiper/css";
import "swiper/css/navigation";
import "./Floorplan.scss";

interface Props {
  formData: any;
  setFormData: any;
  navigation: any;
  model: any;
  totalSum: string;
}

function Floorplan({
  formData,
  setFormData,
  navigation,
  totalSum,
  model,
}: Props) {
  return (
    <section className="floorplan">
      <div className="floorplan__swiper_wrapper">
        <Swiper
          navigation
          loop
          modules={[Navigation]}
          className={`floorplan__image floorplan__image__first ${
            formData.floorPlan.width === 42 && "floorplan__image__inactive"
          }`}
        >
          <SwiperSlide>
            <div className="floorplan__image_wrapper">
              <img
                src={floorPlanWithoutTerrace}
                alt="Floorplan with terrace"
                className="floorplan__image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="floorplan__image_wrapper">
              <img
                src={secondFloorWithoutTerrace}
                alt="second floor floorplan with terrace"
                className="floorplan__image"
              />
            </div>
          </SwiperSlide>
        </Swiper>

        <Swiper
          loop
          navigation
          modules={[Navigation]}
          className={`floorplan__image floorplan__image__secondary ${
            formData.floorPlan.width === 42 &&
            "floorplan__image__secondary_active"
          }`}
        >
          <SwiperSlide>
            <div className="floorplan__image_wrapper">
              <img
                src={floorPlanWithTerrace}
                alt="floorPlanWithoutTerrace"
                className="floorplan__image"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="floorplan__image_wrapper">
              <img
                src={secondFloorWithTerrace}
                alt="secondFloorWithoutTerrace"
                className="floorplan__image"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="floorplan__content">
        <h2 className="floorplan__title">Your Floorplan:</h2>

        <button
          className={`floorplan__button ${
            formData.floorPlan.width === 39 && "floorplan__button_active"
          }`}
          onClick={() =>
            setFormData({
              target: {
                name: "floorPlan",
                value: { width: 39, price: 0 },
              },
            })
          }
        >
          <p className="floorplan__button__paragraph">PlanA</p>
          <p className="floorplan__button__paragraph">0 &#36;</p>
        </button>

        <button
          className={`floorplan__button ${
            formData.floorPlan.width === 42 && "floorplan__button_active"
          }`}
          onClick={() =>
            setFormData({
              target: {
                name: "floorPlan",
                value: { width: 42, price: 10000 },
              },
            })
          }
        >
          <p className="floorplan__button__paragraph">PlanB</p>
          <p className="floorplan__button__paragraph">10,000 &#36;</p>
        </button>

        <div className="floorplan__price">
          <p>Total Price</p>
          <p>
            {totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#36;
          </p>
        </div>
        <button
          className="floorplan__price__button"
          onClick={() => navigation.next()}
        >
          NEXT
        </button>
      </div>
    </section>
  );
}

export default Floorplan;

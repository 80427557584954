import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Logo from "../../gfx/logo.svg";
import Constructor from "../../pages/Constructor/Constructor";
import Models from "../Models/Models";
import "./App.scss";

function App() {
  return (
    <div className="app">
      <Link to="/">
        <img className="app__logo" alt="logo" src={Logo} />
      </Link>
      <Routes>
        <Route path="/" element={<Models />} />
        <Route path="/constructor" element={<Constructor />} />
      </Routes>
    </div>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import "./Models.scss";
import KLX from "../../gfx/KLX/KLX.png";
import KLM from "../../gfx/KLM/KLM.png";
import KL2 from "../../gfx/KL2/KL2.png";

function Models() {
  return (
    <section className="models">
      <div className="models__wrapper">
        <h2 className="models__text">Style Your KODA</h2>
        <p className="models__paragraph">
          Click through the new configurator to pick a perfect plan, kitchen
          type, exterior and interior colors.
        </p>
      </div>
      <div className="models__images">
        <div className="models__image_wrapper">
          <div style={{ height: "42px", background: "#798188" }}></div>
          <img className="models__image" src={KLX} alt="Model KLX" />
          <p className="models__image_paragraph">KODA Loft Extended</p>
          <Link
            to="/constructor"
            state={{ model: "KLX" }}
            className="models__image_link models__image_link_active"
          >
            CUSTOMIZE
          </Link>
        </div>
        <div className="models__image_wrapper">
          <div style={{ height: "42px", background: "#798188" }}></div>
          <p className="models__image_paragraph">KODA Loft</p>
          <img className="models__image" src={KL2} alt="Model KL2" />
          <button disabled className="models__image_link">
            CUSTOMIZE
          </button>
        </div>
        <div className="models__image_wrapper">
          <div style={{ height: "42px", background: "#798188" }}></div>
          <p className="models__image_paragraph">KODA Loft Micro</p>
          <img className="models__image" src={KLM} alt="Model KLM" />
          <button disabled className="models__image_link">
            CUSTOMIZE
          </button>
        </div>
      </div>
    </section>
  );
}

export default Models;
